<template>
	<div class="view-home">
		<div class="home-banner-wrap">
			<!-- <div class="banner-left">
				<div class="banner-item">
					<div class="item-header flex">
						<div class="icon">
							<img class="w-h-full" src="../../assets/images/feiji.png" alt="" srcset="" />
						</div>
						<div class="title">INSTANT DELIVERY</div>
					</div>
					<div class="item-center">
						<div class="text">We provide Instant Emai Delivery</div>
					</div>
				</div>
				<div class="banner-item">
					<div class="item-header flex">
						<div class="icon">
							<img class="w-h-full" src="../../assets/images/anquan.png" alt="" srcset="" />
						</div>
						<div class="title">SAFE PAYMENTS</div>
					</div>
					<div class="item-center">
						<div class="text">Pay safely with PayPal Credit Cards and Cryptocurrencies</div>
					</div>
				</div>
			</div> -->
			<div class="home-banner">
				<el-carousel :interval="5000" class="banner-list" trigger="click" height="6.3rem">
					<el-carousel-item @click.native="bannerOnClick(item)" class="banner-item point" v-for="item in bannerList" :key="item.id">
						<img class="w-h-full banner-img" :src="imgbaseUrl + item.image" alt="" />
					</el-carousel-item>
				</el-carousel>
			</div>
			<div class="home-banner-bottom">
				<div class="banner-item">
					<div class="item-header flex">
						<div class="icon">
							<img class="h-full" src="../../assets/images/feiji.png" alt="" srcset="" />
						</div>
						<div class="title">INSTANT DELIVERY</div>
					</div>
					<div class="item-center">
						<div class="text">We provide Instant Emai Delivery</div>
					</div>
				</div>
				<div class="banner-item">
					<div class="item-header flex">
						<div class="icon">
							<img class="h-full" src="../../assets/images/xiaolian.png" alt="" srcset="" />
						</div>
						<div class="title">NO EXTRA CHARGES</div>
					</div>
					<div class="item-center">
						<div class="text">All our prices are Fixed and you don't have to pay any extra fees</div>
					</div>
				</div>
				<div class="banner-item">
					<div class="item-header flex">
						<div class="icon">
							<img class="h-full" src="../../assets/images/anquan.png" alt="" srcset="" />
						</div>
						<div class="title">SAFE PAYMENTS</div>
					</div>
					<div class="item-center">
						<div class="text">Pay safely with PayPal Credit Cards and Cryptocurrencies</div>
					</div>
				</div>
				<div class="banner-item">
					<div class="item-header flex">
						<div class="icon">
							<img class="h-full" src="../../assets/images/kefu.png" alt="" srcset="" />
						</div>
						<div class="title">24/7 SUPPORT</div>
					</div>
					<div class="item-center">
						<div class="text">Our Support Team is available 24/7 at your service</div>
					</div>
				</div>
			</div>
			<!-- <div class="banner-right">
				<div class="banner-item">
					<div class="item-header flex">
						<div class="icon">
							<img class="w-h-full" src="../../assets/images/xiaolian.png" alt="" srcset="" />
						</div>
						<div class="title">NO EXTRA CHARGES</div>
					</div>
					<div class="item-center">
						<div class="text">All our prices are Fixed and you don't have to pay any extra fees</div>
					</div>
				</div>
				<div class="banner-item">
					<div class="item-header flex">
						<div class="icon">
							<img class="w-h-full" src="../../assets/images/kefu.png" alt="" srcset="" />
						</div>
						<div class="title">24/7 SUPPORT</div>
					</div>
					<div class="item-center">
						<div class="text">Our Support Team is available 24/7 at your service</div>
					</div>
				</div>
			</div> -->
		</div>
		<div class="product-list-box">
			<div v-show="item.goods_arr.length" v-for="(item, index) in homeProductLsit" :key="index" class="product-list-item point">
				<div class="list-item-title">{{ item.category_name }}</div>
				<div class="product-list">
					<div @click="$router.push(`/goodsdetail?id=${vItem.id}`)" v-for="vItem in item.goods_arr" :key="vItem.id" class="product-item-wrap">
						<div class="product-item">
							<div class="img-box">
								<img class="h-full" :src="vItem.image" alt="" />
							</div>
							<div class="text-box">
								<div class="t-box-title elips-2">{{ vItem.title }}</div>
								<div class="t-box-price">$ {{ vItem.price }}</div>
							</div>
							<div class="cart-box flex">
								<div @click.stop="checkOutOnClick(vItem)" class="left-buy-btn flex-c">Check Out</div>
								<div @click.stop="addToCarOnClick(vItem)" class="right-cart-btn flex-c">
									<img src="@/assets/images/cart.png" alt="" />
								</div>
							</div>
						</div>
						{{ vItem?.stocks }}
						<div v-if="vItem?.stocks == 0" class="item-sold-out">
							<img class="mskicon" src="../../assets/images/pc-sellout.png" alt="" />
						</div>
					</div>
				</div>
				<div @click="$router.push(`/goodsList?lv1id=${item.category_id}`)" class="view-more point">
					<div class="text">View more</div>
					<div class="icon">
						<img style="height: 100%" src="../../assets/images/right-arrow-2.png" alt="" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapGetters, mapState, mapActions, mapMutations } from 'vuex';
	import { getBannerListApi, getHomeProductListApi, addCarApi } from '@/network/api.js';
	export default {
		data() {
			return {
				bannerList: [],
				homeProductLsit: [],
				localCarList: []
			};
		},
		computed: {
			...mapGetters(['isLogin']),
			...mapState(['imgbaseUrl', 'carList'])
		},
		beforeDestroy() {
			drift.api.hideChat();
		},
		methods: {
			...mapMutations(['setCarList']),
			...mapActions(['ReFreshUserInfo', 'getCartList']),
			init() {
				this.localCarList = this.carList || [];
				this.getBannerListFun();
				this.getHomeProductListFun();
			},
			bannerOnClick(item) {
				console.log(123, item);
				if (item.href) {
					this.$router.push({ path: item.href });
				}
			},
			async getBannerListFun() {
				const res = await getBannerListApi();
				this.bannerList = res.data;
			},
			async getHomeProductListFun() {
				const res = await getHomeProductListApi();
				this.homeProductLsit = res.data;
				console.log('首页商品', this.homeProductLsit);
			},
			async checkOutOnClick(item) {
				await this.addToCarOnClick(item);
				this.$router.push('/cartDetail');
			},
			async addToCarOnClick(item, inNum = 1) {
				if (this.isLogin) {
					const params = {
						goods_id: item.id,
						nums: inNum
					};
					try {
						const res = await addCarApi(params);
						console.log('加入购物车结果', res);
						this.$message.success('Added successfully');
						this.getCartList();
					} catch (error) {}
				} else {
					const ids = this.localCarList.map((wItem) => wItem.goods_id);
					if (!ids.includes(item.id)) {
						this.localCarList.push({
							goods: item,
							nums: inNum,
							goods_id: item.id
						});
					} else {
						this.localCarList.forEach((vItem) => {
							if (vItem.goods_id === item.id) {
								vItem.nums = vItem.nums * 1 + inNum * 1;
							}
						});
					}
					this.setCarList(this.localCarList);
					console.log('加入购物车结果', this.localCarList);
					this.$message.success('Added successfully');
				}
			}
		},
		mounted() {
			this.init();
		}
	};
</script>

<style lang="scss">
	.view-home {
		.home-banner-wrap {
			padding: 0.3rem 3.4rem;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-wrap: wrap;
			// background: #f9f9f9;
			margin-bottom: 0.6rem;
			.home-banner {
				// margin-bottom: 0.8rem;
				width: 100%;
				// margin: 0 0.4rem;
				margin-bottom: 0.34rem;
				border-radius: 0.2rem;
				overflow: hidden;
				.banner-list {
					.el-carousel__container {
						height: 100%;
						.el-carousel__arrow--left {
							width: 0.36rem;
							height: 0.36rem;
							@include pcurl('banner-right-arrow.png');
							transform: rotate(180deg);
							i {
								display: none;
							}
						}
						.el-carousel__arrow--right {
							width: 0.36rem;
							height: 0.36rem;
							@include pcurl('banner-right-arrow.png');
							i {
								display: none;
							}
						}
					}
					.el-carousel__indicators {
						display: flex;
						align-items: center;
						justify-content: center;
						bottom: 0rem;
					}
					.el-carousel__indicator {
						display: flex;
						align-items: center;
						justify-content: center;
						.el-carousel__button {
							width: 0.24rem;
							height: 0.24rem;
							border-radius: 50%;
							opacity: 1;
							background: unset;
							display: flex;
							align-items: center;
							justify-content: center;
						}
						.el-carousel__button::after {
							content: '';
							display: block;
							width: 60%;
							height: 60%;
							border-radius: 50%;
							background: rgba(175, 175, 175, 1);
							border: 0.03rem solid unset;
						}
					}
					.is-active {
						.el-carousel__button {
							background: #fff;
						}
						.el-carousel__button::after {
							content: '';
							display: block;
							width: 60%;
							height: 60%;
							border-radius: 50%;
							background: #fff;
							border: 0.03rem solid rgba(217, 217, 217, 1);
						}
					}
				}
			}
			.banner-left,
			.banner-right {
				width: 1.98rem;
				flex-shrink: 0;
				height: 3.8rem;
				display: flex;
				align-content: space-between;
				flex-wrap: wrap;
				.banner-item {
					padding: 0.34rem 0;

					.item-header {
						margin-bottom: 0.08rem;
						.icon {
							width: 0.24rem;
							height: 0.24rem;
							margin-right: 0.1rem;
							margin-top: -0.03rem;
						}
						.title {
							font-weight: 500;
							font-size: 0.16rem;
							color: #333333;
							white-space: nowrap;
						}
					}
					.item-center {
						font-size: 0.12rem;
						color: #666666;
						.text {
							line-height: 0.19rem;
						}
					}
				}
			}
			.home-banner-bottom {
				width: 100%;
				display: flex;
				align-items: flex-start;
				justify-content: space-between;
				.banner-item {
					// padding: 0.34rem 0;
					width: 1.8rem;
					flex-shrink: 0;
					.item-header {
						margin-bottom: 0.08rem;
						.icon {
							width: 0.24rem;
							height: 0.24rem;
							margin-right: 0.1rem;
							margin-top: -0.03rem;
						}
						.title {
							font-weight: 500;
							font-size: 0.16rem;
							color: #333333;
							white-space: nowrap;
						}
					}
					.item-center {
						font-size: 0.12rem;
						color: #666666;
						.text {
							line-height: 0.19rem;
						}
					}
				}
			}
		}
		.product-list-box {
			padding: 0 3.4rem;
			box-sizing: border-box;
			text-align: center;
			margin-bottom: 1.8rem;
			.product-list-item {
				margin-bottom: 0.8rem;
				.list-item-title {
					margin-bottom: 0.6rem;
					font-size: 0.28rem;
					font-weight: 550;
					position: relative;
				}
				.list-item-title::before {
					content: '';
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					left: 0;
					width: 5.3rem;
					border: 0.02rem solid;
					border-image: linear-gradient(90deg, rgba(179, 205, 219, 0), rgba(179, 205, 219, 1)) 2 2;
				}
				.list-item-title::after {
					content: '';
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					right: 0;
					width: 5.3rem;
					border: 0.02rem solid;
					border-image: linear-gradient(90deg, rgba(179, 205, 219, 1), rgba(179, 205, 219, 0)) 2 2;
				}
				.product-list {
					display: flex;
					align-items: flex-start;
					margin-bottom: 0.4rem;
					.product-item-wrap {
						width: 2.68rem;
						height: 4.04rem;
						flex-shrink: 0;
						position: relative;
						margin-right: 0.56rem;
						.product-item {
							width: 100%;
							height: 100%;
							position: absolute;
							top: 0;
							left: 0;
							flex-shrink: 0;
							background: #ffffff;
							box-shadow: 0px 0.14rem 0.35rem 0px rgba(0, 0, 0, 0.2), 0px 0.01rem 0.03rem 0px rgba(0, 0, 0, 0.2);

							display: flex;
							flex-direction: column;
							.img-box {
								padding: 0.18rem 0.13rem 0.15rem;
								box-sizing: border-box;
								max-height: 2.42rem;
								display: flex;
								align-items: center;
								justify-content: center;
							}
							.text-box {
								padding: 0 0.13rem;
								text-align: center;
								flex-grow: 1;
								.t-box-title {
									font-size: 0.18rem;
									line-height: 0.21rem;
									margin-bottom: 0.04rem;
								}
								.t-box-price {
									font-weight: 500;
									font-size: 0.24rem;
									line-height: 0.28rem;
								}
							}
							.cart-box {
								width: 100%;
								height: 0.44rem;
								align-content: flex-end;
								background: #4182a4;
								.left-buy-btn {
									flex-grow: 1;
									font-size: 0.2rem;
									height: 100%;
									color: #fff;
								}
								.right-cart-btn {
									background: #d9e6ed;
									width: 0.56rem;
									height: 100%;
									img {
										width: 0.3rem;
										height: 0.3rem;
									}
								}
							}
						}
						.item-sold-out {
							position: absolute;
							width: 100%;
							height: 100%;
							background: rgba(0, 0, 0, 0.4);
							top: 0;
							left: 0;
							.mskicon {
								position: absolute;
								width: 0.68rem;
								height: 0.68rem;
								right: 0.16rem;
								bottom: 0.48rem;
							}
						}
					}
					.product-item-wrap:nth-of-type(4n) {
						margin-right: 0rem;
					}
				}
				.view-more {
					width: 1.37rem;
					height: 0.28rem;
					border: 0.01px solid #d9e6ed;
					display: flex;
					align-items: center;
					justify-content: center;
					.text {
						margin-right: 0.1rem;
						font-size: 0.14rem;
						color: #4182a4;
						font-weight: 400;
					}
					.icon {
						width: 0.18rem;
						height: 0.18rem;
					}
				}
			}
		}
	}
</style>
